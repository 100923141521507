<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <Form @submit="handleSubmit" v-slot="{ errors }" class="forms-setting-form" ref="broadcast-form">
                    <div class="step_wpr content_area container">
                        <div class="info_bar">
                            <div class="step_bar2">
                                <ul>
                                    <li @click="toggleStep(1);" :class="{ 'active' : step == 1 || step > 1}">
                                        <span>1</span>
                                        <h6>Content</h6>
                                    </li>
                                    <li @click="toggleStep(2)" :class="{ 'active' : step == 2 || step > 2}">
                                        <span>2</span>
                                        <h6>Recipients</h6>
                                    </li>
                                    <li @click="toggleStep(3);" :class="{ 'active' : step == 3 || step > 3}">
                                        <span>3</span>
                                        <h6>Schedule</h6>
                                    </li>
                                </ul>
                                <div class="total_bar"><span :style="`width:${(100 / 2) * (step - 1) }%`"></span></div>
                            </div>
                            <button type="button" class="tutorial_btn" @click="handleTutorialVideo()">Tutorial</button>
                        </div>
                        <div class="content_wpr" v-if="step == 1">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Content</h2>
                                </div>
                                <div class="setting_wpr lg_editor">
                                    <email-component v-model="emailContent" :errors="errors" :handle-default-email="handleDefaultEmail" preview-module="broadcast" ref="email-component" />
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="step == 2">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Recipients</h2>
                                </div>
                                <div class="color_container">
                                    <div class="setting_wpr">
                                        <h3 class="sub_header">
                                            Who will this broadcast be from?
                                        </h3>
                                        <div class="form_grp mb-1">
                                            <div class="group_item">
                                                <label class="input_label">Name</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.sender_name }">
                                                    <Field autocomplete="off" name="sender_name" v-model="form.sender_name" type="text" placeholder="John" rules="required" label="sender name" />
                                                </div>
                                                <ErrorMessage name="sender_name" class="text-danger" />
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Email</label>
                                                <div class="field_wpr has_prefix" :class="{ 'has-error': errors.sender_email }">
                                                    <Field autocomplete="off" name="sender_email" type="email" v-model="form.sender_email" placeholder="john.doe@onboardme.io" rules="email|required" label="sender email" />
                                                    <span class="prefix"><i class="fas fa-envelope"></i></span>
                                                </div>
                                                <ErrorMessage name="sender_email" class="text-danger" />
                                            </div>
                                        </div>
                                        <h3 class="sub_header">
                                            Who will receive this broadcast?
                                        </h3>
                                        <div class="reciever_wpr">
                                            Matching
                                            <!-- {{ form.param }} -->
                                            <multiselect v-model="form.param" v-bind="recieverType" class="small_box"></multiselect>
                                            of the following
                                        </div>
                                    </div>
                                </div>
                                <template v-for="(condition, c) of form.conditions" :key="c">
                                    <div class="color_container mt-3">
                                        <div class="close_area" @click="deleteCondition(c)"><i class="fas fa-times"></i></div>
                                        <div class="section_wpr">
                                            <h3><div class="cloud_tag">{{ filterLabels[condition.type] }}</div></h3>
                                            <!-- <h3 class="sub_header m-0">{{ filterLabels[condition.type] }}</h3> -->
                                            <ul class="filter_tags" v-if="condition.ids.length">
                                                <li v-for="(item, index) in condition.ids" :key="index">{{ getFilterName(condition.type, item) }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="text-center mt-3">
                                        <h4 v-if="form.param === 'all' && (form.conditions.length - 1) > c">AND</h4>
                                        <h4 v-else-if="form.param === 'any' && (form.conditions.length - 1) > c">OR</h4>
                                        <h4 v-else-if="form.param === 'exclude' && (form.conditions.length - 1) > c">OR</h4>
                                    </div>
                                </template>
                                <button type="button" class="primary_btn mt-5" @click="filterModal = true">+ Add Filter</button>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="step == 3">
                            <div class="section_content w-100">
                                <div class="edit_section">
                                    <ul class="broadcast_info">
                                        <li>
                                            <span><img src="@/assets/images/email.svg" alt=""/></span>
                                            <h4>Status:</h4>
                                            <label class="draft">{{ getStatus(broadcast) }}</label>
                                        </li>
                                        <li>
                                            <span><img src="@/assets/images/users.svg"/></span>
                                            <p v-if="recipientLoader">Loading...</p>
                                            <p v-else>This will be sent to {{ parseInt(recipient.recipients) - parseInt(recipient.suppressed) }} of your {{ recipient.recipients }} total {{ parseInt(recipient.recipients) > 1 ? ' contacts' : ' contact' }} because {{ parseInt(recipient.suppressed) > 1 ? recipient.suppressed + ' contacts are' : recipient.suppressed + ' contacts is' }} on the <a @click="suppressionModal = true">suppression list</a>.</p>
                                        </li>
                                    </ul>
                                </div>
                                <div class="edit_section pb-0 mt-3 px-5" v-if="scheduleArea">
                                    <h3 class="sub_header mt-2">Schedule</h3>
                                    <div class="setting_wpr pb-4">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Date</label>
                                                <div class="field_wpr">
                                                    <datepicker v-model="form.schedule_date" :min-date="minStartDate" :timezone="'utc'" auto-apply :enableTimePicker="false" placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                                </div>
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Timezone</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                    v-model="form.timezone"
                                                    :options="timezones"
                                                    valueProp="timezone"
                                                    label="timezone"
                                                    :searchable="true"
                                                    >
                                                        <template v-slot:option="{ option }">
                                                            <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <div class="group-col-3">
                                                    <div class="field_wpr">
                                                        <multiselect v-model="form.schedule_hour" v-bind="hours"></multiselect>
                                                    </div>
                                                    <div class="field_wpr">
                                                        <multiselect v-model="form.schedule_minute" v-bind="mins"></multiselect>
                                                    </div>
                                                    <div class="field_wpr">
                                                        <multiselect v-model="form.schedule_hour_type" v-bind="clock"></multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="action_wpr footer_area">
                                        <button class="btn cancel_btn" @click="scheduleArea = false;" type="button">Cancel</button>
                                        <button class="btn save_btn" @click="handleQueueBroadcast()" type="button">
                                            <i class="fa fa-spin fa-spinner" v-if="broadcastSaveLoader && isScheduled"></i>
                                            {{ broadcastSaveLoader && isScheduled ? 'Queuing' : 'Que Broadcast' }}
                                        </button>
                                    </div>
                                </div>
                                <div class="action_wpr mt-5" v-if="recipient.recipients && !recipientLoader && !scheduleArea">
                                    <template v-if="hasTrialPeriod && ((recipient.recipients > 2000) || totalBroadcastEmailsSent >= 2000)">
                                        <button type="button" class="btn cancel_btn" @click="handleBroadcastTrialLimitAlert()"><img src="@/assets/images/calendar-alt.svg">Schedule This Broadcast</button>
                                        <button type="button" class="btn save_btn" @click="handleBroadcastTrialLimitAlert()">Send Now</button>
                                    </template>
                                    <template v-else>
                                        <button type="button" class="btn cancel_btn" @click="scheduleArea = !scheduleArea"><img src="@/assets/images/calendar-alt.svg">Schedule This Broadcast</button>
                                        <button type="button" class="btn save_btn" @click="handleSendBroadcast()">Send Now</button>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="preview_btn" @click="preview = true" v-if="step == 1">Preview</button>
                    </div>
                    <div class="action_wpr">
                        <button type="button" class="btn cancel_btn" @click="close" v-if="step == 1">Close</button>
                        <button type="button" class="btn cancel_btn" @click="step -= 1" v-if="step > 1">Back</button>
                        <button type="submit" class="btn save_btn" v-if="step < 3"><i class="fa fa-spin fa-spinner" v-if="broadcastSaveLoader && !isScheduled"></i> {{ broadcastSaveLoader && !isScheduled ? 'Saving' : 'Next' }}</button>
                        <button type="submit" id="broadcast-save-button" class="btn save_btn" v-if="step == 3"><i class="fa fa-spin fa-spinner" v-if="broadcastSaveLoader && !isScheduled"></i> {{ broadcastSaveLoader && !isScheduled ? 'Saving' : 'Save Draft' }}</button>
                        <button type="button" class="btn save_btn m_btn" v-if="step == 3" @click="actionArea">Next</button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_msg" class="switch_option capsule_btn p-0">
                            <h5 class="mr-2">Preview</h5>
                            <input type="checkbox" id="preview_msg" v-model="previewContent" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <div class="msg_preview">
                            <h2>{{ replaceVariables(emailContent.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>{{ form.sender_name }}</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                    <p>{{ moment(broadcast.schedule_datetime).format('MMM DD, YYYY | hh:mm A') +' (' + broadcast.formatted_tz + ')' }}</p>
                                </div>
                            </div>
                            <div class="email-logo" v-if="emailContent.show_email_logo">
                                <img :src="emailContent.email_logo ? emailContent.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(emailContent.email)"></div>
                            <div v-html="generateSignature(emailContent.is_signature, emailContent.signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mt-2 mb-2">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <broadcast-filter v-model="filterModal" :handle-add-filter="handleAddFilter" />
        <suppression-list v-model="suppressionModal" :broadcast-id="form.id" />
        <preview-content v-model="previewContent" :broadcast="selectedBroadcast" />
        <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapGetters, mapActions } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const BroadcastFilter = defineAsyncComponent(() => import('@/pages/broadcast/components/Filter'))
    const SuppressionList = defineAsyncComponent(() => import('@/pages/broadcast/components/SuppressionList'))
    const PreviewContent = defineAsyncComponent(() => import('@/pages/broadcast/components/PreviewContent'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import moment from 'moment'

    export default {
        name: 'Broadcast Edit',

        data () {
            return {
                preview: true,
                previewContent: false,
                whiteLabel: {},
                env: {},
                moment,
                step: 1,
                form: {
                    schedule_date: '',
                    schedule_hour: '07',
                    schedule_minute: '00',
                    schedule_hour_type: 'AM',
                    timezone: this.user ? this.user.timezone : '',
                },
                emailContent: {
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                    defaultHandler: true,
                },
                recieverType: {
                    mode: 'single',
                    value: ['exclude'],
                    options: [
                        { value: 'and', label: 'All' },
                        { value: 'or', label: 'Any' },
                        { value: 'none', label: 'None' },
                    ],
                },
                filterLabels: {
                    all: 'ALL CONTACTS',
                    tags: 'SUBSCRIBED TO TAGS:',
                    groups: 'WITHIN GROUPS:',
                    tabs: 'WITHIN TABS:'
                },
                filterModal: false,
                scheduleArea: false,
                minStartDate: moment().format('YYYY-MM-DD'),
                mins: {
                    mode: 'single',
                    value: ['00'],
                    options: [
                        { value: '00', label: '00' },
                        { value: '01', label: '01' },
                        { value: '02', label: '02' },
                        { value: '03', label: '03' },
                        { value: '04', label: '04' },
                        { value: '05', label: '05' },
                        { value: '06', label: '06' },
                        { value: '07', label: '07' },
                        { value: '08', label: '08' },
                        { value: '09', label: '09' },
                        { value: '10', label: '10' },
                        { value: '11', label: '11' },
                        { value: '12', label: '12' },
                        { value: '13', label: '13' },
                        { value: '14', label: '14' },
                        { value: '15', label: '15' },
                        { value: '16', label: '16' },
                        { value: '17', label: '17' },
                        { value: '18', label: '18' },
                        { value: '19', label: '19' },
                        { value: '20', label: '20' },
                        { value: '21', label: '21' },
                        { value: '22', label: '22' },
                        { value: '23', label: '23' },
                        { value: '24', label: '24' },
                        { value: '25', label: '25' },
                        { value: '26', label: '26' },
                        { value: '27', label: '27' },
                        { value: '28', label: '28' },
                        { value: '29', label: '29' },
                        { value: '30', label: '30' },
                        { value: '31', label: '31' },
                        { value: '32', label: '32' },
                        { value: '33', label: '33' },
                        { value: '34', label: '34' },
                        { value: '35', label: '35' },
                        { value: '36', label: '36' },
                        { value: '37', label: '37' },
                        { value: '38', label: '38' },
                        { value: '39', label: '39' },
                        { value: '40', label: '40' },
                        { value: '41', label: '41' },
                        { value: '42', label: '42' },
                        { value: '43', label: '43' },
                        { value: '45', label: '45' },
                        { value: '46', label: '46' },
                        { value: '47', label: '47' },
                        { value: '48', label: '48' },
                        { value: '49', label: '49' },
                        { value: '50', label: '50' },
                        { value: '51', label: '51' },
                        { value: '52', label: '52' },
                        { value: '53', label: '53' },
                        { value: '54', label: '54' },
                        { value: '55', label: '55' },
                        { value: '56', label: '56' },
                        { value: '57', label: '57' },
                        { value: '58', label: '58' },
                        { value: '59', label: '59' },
                    ],
                    createTag: true
                },
                hours: {
                    mode: 'single',
                    value: ['7'],
                    options: [
                        { value: '01', label: '01' },
                        { value: '02', label: '02' },
                        { value: '03', label: '03' },
                        { value: '04', label: '04' },
                        { value: '05', label: '05' },
                        { value: '06', label: '06' },
                        { value: '07', label: '07' },
                        { value: '08', label: '08' },
                        { value: '09', label: '09' },
                        { value: '10', label: '10' },
                        { value: '11', label: '11' },
                        { value: '12', label: '12' },
                    ],
                    createTag: true
                },
                clock: {
                    mode: 'single',
                    value: ['AM'],
                    options: [
                        { value: 'AM', label: 'AM' },
                        { value: 'PM', label: 'PM' }
                    ],
                    createTag: true
                },
                suppressionModal: false,
                selectedBroadcast: {},
                isScheduled: false,
                recipientLoader: false,
                isTyping: false,
                tutorial: false,
                tutorialVideo: 'https://www.loom.com/embed/0e1a582fd746457cbe499b03949f2f48?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
            };
        },

        props: {
            modelValue: Boolean,
            broadcast: Object,
            refreshBroadcast: {
                type: Function,
                default: () => {},
            },
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            Field,
            ErrorMessage,
            BroadcastFilter,
            SuppressionList,
            PreviewContent,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                vm.step = 1;

                if (value) {
                    document.body.classList.add('modal-open');
                    vm.resetForm();
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            previewContent (value) {
                if (value) {
                    const vm = this;

                    vm.selectedBroadcast = JSON.parse(JSON.stringify(vm.broadcast));

                    vm.selectedBroadcast.subject          = vm.emailContent.subject;
                    vm.selectedBroadcast.content          = vm.emailContent.email;
                    vm.selectedBroadcast.is_signature     = vm.emailContent.is_signature;
                    vm.selectedBroadcast.signature_id     = vm.emailContent.signature_id;
                    vm.selectedBroadcast.show_email_logo  = vm.emailContent.show_email_logo;
                    vm.selectedBroadcast.email_logo       = vm.emailContent.email_logo;
                    vm.selectedBroadcast.sender_name      = vm.form.sender_name;
                }
            },

            step (step) {
                if (step == 3) {
                    const vm = this;
                    vm.recipientLoader = true;

                    vm.getBroadcastReciept(vm.form.id).then((result) => {
                        vm.recipientLoader = false;
                    });
                }
            },

            'emailContent.email': {
                handler () {
                    const vm = this;

                    vm.isTyping = true;
                },
                deep: true,
            },

            isTyping (val) {
                const vm = this;


                if (!vm.isTyping) {
                    vm.handleAutoSave();
                }

                if (val) {
                    setTimeout(() => {
                        vm.isTyping = false;
                    }, 5000);
                }
            }
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                hasTrialPeriod: state => state.authModule.hasTrialPeriod,
                membership: state => state.authModule.membership,
                tags: state => state.tagModule.tags,
                groups: state => state.groupModule.groups,
                statuses: state => state.contactModule.statusList,
                timezones: state => state.commonModule.timezones,
                broadcastSaveLoader: state => state.broadcastModule.broadcastSaveLoader,
                recipient: state => state.broadcastModule.recipient,
                totalBroadcastEmailsSent: state => state.broadcastModule.totalBroadcastEmailsSent,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
            vm.env = process.env;

            if (vm.tags.length == 0) {
                vm.getTags();
            }

            if (vm.groups.length == 0) {
                vm.getGroups();
            }

            if (vm.statuses.length == 0) {
                vm.getStatuses();
            }

            if (vm.timezones.length == 0) {
                vm.getTimezones();
            }

            window.addEventListener('resize', vm.resizeEventHandler);
            vm.resizeEventHandler();
        },

        unmounted() {
            const vm = this;
            window.removeEventListener('resize', vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                getTags: 'tagModule/getTags',
                getGroups: 'groupModule/getGroups',
                getStatuses: 'contactModule/getStatuses',
                getTimezones: 'commonModule/getTimezones',
                saveBroadcast: 'broadcastModule/saveBroadcast',
                getBroadcastReciept: 'broadcastModule/getBroadcastReciept',
                sendBroadcast: 'broadcastModule/sendBroadcast',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.emailContent.subject         = vm.broadcast.subject;
                vm.emailContent.email           = vm.broadcast.content;
                vm.emailContent.is_signature    = vm.broadcast.is_signature;
                vm.emailContent.signature_id    = vm.broadcast.signature_id;
                vm.emailContent.show_email_logo = vm.broadcast.show_email_logo;
                vm.emailContent.email_logo      = vm.broadcast.email_logo;

                vm.form.id            = vm.broadcast.id;
                vm.form.conditions    = vm.broadcast.conditions ? JSON.parse(vm.broadcast.conditions) : [];
                vm.form.sender_name   = vm.broadcast.sender_name ? vm.broadcast.sender_name : vm.user.full_name;
                vm.form.sender_email  = vm.broadcast.sender_email ? vm.broadcast.sender_email : (vm.user.reply_to_email ? vm.user.reply_to_email : vm.user.email);
                vm.form.timezone      = vm.broadcast.timezone ? vm.broadcast.timezone : vm.user.timezone;
                vm.form.param         = vm.broadcast.param ? vm.broadcast.param : 'and';

                if (vm.broadcast.schedule_datetime != null && vm.broadcast.schedule_datetime != '0000-00-00 00:00:00') {
                    vm.form.schedule_date       = vm.broadcast.schedule_datetime;
                    vm.form.schedule_hour       = moment(vm.broadcast.schedule_datetime).format('hh')
                    vm.form.schedule_minute     = moment(vm.broadcast.schedule_datetime).format('mm')
                    vm.form.schedule_hour_type  = moment(vm.broadcast.schedule_datetime).format('a').toUpperCase()
                } else {
                    vm.form.schedule_date       = moment(new Date()).format('YYYY-MM-DD')
                    vm.form.schedule_hour       = '07'
                    vm.form.schedule_minute     = '00'
                    vm.form.schedule_hour_type  = 'AM'
                }

                vm.isScheduled = false;
            },

            handleDefaultEmail () {
                const vm = this;

                vm.emailContent.subject = vm.broadcast.subject;
                vm.emailContent.email   = vm.broadcast.content;
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = JSON.parse(JSON.stringify((vm.form)));

                params.setFieldError    = setFieldError;
                params.subject          = vm.emailContent.subject;
                params.content          = vm.emailContent.email;
                params.email_logo       = vm.emailContent.email_logo;
                params.show_email_logo  = vm.emailContent.show_email_logo;
                params.is_signature     = vm.emailContent.is_signature;
                params.signature_id     = vm.emailContent.signature_id;
                params.is_scheduled     = (vm.isScheduled || vm.broadcast.is_scheduled == 2) ? 2 : 1;

                if (params.schedule_date) {
                    params.schedule_date      = moment.utc(params.schedule_date).format('YYYY-MM-DD');
                    params.schedule_datetime  = moment.utc(`${params.schedule_date} ${params.schedule_hour}:${params.schedule_minute} ${params.schedule_hour_type}`, ['YYYY-MM-DD h:mm A']).format('YYYY-MM-DD HH:mm:ss');
                } else {
                    params.schedule_datetime  = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }

                vm.saveBroadcast(params).then((broadcast) => {
                    if (broadcast) {
                        vm.selectedBroadcast = broadcast;
                        vm.form.id           = broadcast.id;

                        if (vm.step < 3) {
                            vm.step += 1;
                        } else {
                            vm.closeModal();
                        }

                        vm.refreshBroadcast();
                    }
                });
            },

            handleAutoSave () {
                const vm        = this;
                const params    = JSON.parse(JSON.stringify((vm.form)));
                const form      = vm.$refs['broadcast-form'];

                params.setFieldError    = form.setFieldError;
                params.subject          = vm.emailContent.subject;
                params.content          = vm.emailContent.email;
                params.email_logo       = vm.emailContent.email_logo;
                params.show_email_logo  = vm.emailContent.show_email_logo;
                params.is_signature     = vm.emailContent.is_signature;
                params.signature_id     = vm.emailContent.signature_id;
                params.is_scheduled     = (vm.isScheduled || vm.broadcast.is_scheduled == 2) ? 2 : 1;

                if (params.schedule_date) {
                    params.schedule_date      = moment.utc(params.schedule_date).format('YYYY-MM-DD');
                    params.schedule_datetime  = moment.utc(`${params.schedule_date} ${params.schedule_hour}:${params.schedule_minute} ${params.schedule_hour_type}`, ['YYYY-MM-DD h:mm A']).format('YYYY-MM-DD HH:mm:ss');
                } else {
                    params.schedule_datetime  = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }

                vm.saveBroadcast(params).then((broadcast) => {
                    if (broadcast) {
                        vm.selectedBroadcast = broadcast;
                        vm.form.id           = broadcast.id;
                    }
                });
            },

            getFilterName (type, id) {
                const vm = this;
                let obj = [];

                if (type === 'tags') {
                    obj = vm.tags;
                } else if (type === 'groups') {
                    obj = vm.groups;
                } else if (type === 'tabs') {
                    obj = vm.statuses;
                }

                if (type === 'tabs') {
                    obj = obj.find(o => o.value == id);
                } else {
                    obj = obj.find(o => o.id == id);
                }

                if (type === 'tabs' && !(obj)) {
                    return vm.oldTabNamesCompatibility(id);
                }

                if (type === 'tabs') {
                    return (obj) ? obj.title : null;
                }

                return (obj) ? obj.name : null;
            },

            oldTabNamesCompatibility (id) {
                let name = '';

                if (id === 'prospect') {
                    name = 'Leads';
                } else if (id === 'consults') {
                    name = 'Consults';
                } else if (id === 'trial') {
                    name = 'Trial';
                } else if (id === 'bucket') {
                    name = 'Lost';
                } else if (id === 'active_customer') {
                    name = 'Active Clients';
                } else if (id === 'inactive_customer') {
                    name = 'Inactive Clients';
                } else if (id === 'active_staff') {
                    name = 'Active Staff';
                } else if (id === 'inactive_staff') {
                    name = 'Inactive Staff';
                }

                return name;
            },

            deleteCondition (index) {
                const vm = this;

                vm.form.conditions.splice(index, 1);
            },

            handleAddFilter (filter) {
                const vm = this;

                vm.form.conditions.push(filter);
            },

            handleSendBroadcast () {
                const vm = this;

                if (vm.emailContent.email.includes('style="background-color: rgb')) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `This email contains highlighted and possibly unedited text. Are you sure you want to send this to your clients?`);

                    options.preConfirm  = function () {
                                              const options2 = Helper.swalConfirmOptions('Are you sure?', `This will immediately send to ${parseInt(vm.recipient.recipients) - parseInt(vm.recipient.suppressed)} of your contacts and can not be undone.`);

                                              options2.preConfirm = function () {
                                                                      return vm.sendBroadcast(vm.form.id).then((result) => {
                                                                          if (result == 'limit-error') {
                                                                              vm.handleBroadcastTrialLimitAlert();
                                                                          } else if (result) {
                                                                              vm.closeModal();
                                                                              vm.refreshBroadcast();
                                                                          }
                                                                      });
                                                                  };

                                              Swal.fire(options2);
                                          };

                    Swal.fire(options);
                } else {
                    const options = Helper.swalConfirmOptions('Are you sure?', `This will immediately send to ${parseInt(vm.recipient.recipients) - parseInt(vm.recipient.suppressed)} of your contacts and can not be undone.`);

                    options.preConfirm = function () {
                                            return vm.sendBroadcast(vm.form.id).then((result) => {
                                                if (result == 'limit-error') {
                                                    vm.handleBroadcastTrialLimitAlert();
                                                } else if (result) {
                                                    vm.closeModal();
                                                    vm.refreshBroadcast();
                                                }
                                            });
                                        };

                    Swal.fire(options);
                }
            },

            handleQueueBroadcast () {
                const vm = this;

                if (vm.emailContent.email.includes('style="background-color: rgb')) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `This email contains highlighted and possibly unedited text. Are you sure you want to send this to your clients?`);

                    options.preConfirm  = function () {
                                              vm.isScheduled = true;
                                              document.getElementById('broadcast-save-button').click();
                                          };

                    Swal.fire(options);
                } else {
                    vm.isScheduled = true;
                    document.getElementById('broadcast-save-button').click();
                }
            },

            getStatus (broadcast) {
                const vm = this;

                if (broadcast.status == 1 || broadcast.is_automatic == 1) {
                    return (broadcast.broadcast_delay || broadcast.schedule_diff <= 3) ? 'Sending' : 'Completed';
                } else if (broadcast.status != 1 && broadcast.is_scheduled == 1) {
                    return 'Draft';
                } else if (broadcast.status != 1 && broadcast.is_scheduled == 2) {
                    return 'Queued';
                }

                return 'Draft';
            },

            toggleSectionbar () {
                const leftSidebar = document.querySelector('.global_setting');

                if (leftSidebar.classList.contains('show_m_sidebar')) {
                    leftSidebar.classList.remove('show_m_sidebar');
                } else {
                    leftSidebar.classList.add('show_m_sidebar');
                }
            },

            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 992) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },

            actionArea () {
                const leftSidebar = document.querySelector('.global_setting');

                if (leftSidebar.classList.contains('show_m_action')) {
                    leftSidebar.classList.remove('show_m_action');
                } else {
                    leftSidebar.classList.add('show_m_action');
                }
            },

            toggleStep(stp){
                const vm = this;
                vm.step = stp;
            },

            handleTutorialVideo() {
                const vm = this;
                vm.tutorial = true;
            },

            handleBroadcastTrialLimitAlert () {
                const vm = this;
                const recipients = vm.recipient.recipients;
                let message = `You are allowed to send up to 2,000 emails during your trial period. However, you have selected ${recipients} contacts. Please adjust your filter and try again with fewer contacts.`;

                if (vm.totalBroadcastEmailsSent) {
                    message = `You have sent ${vm.totalBroadcastEmailsSent} emails out of your trial limit of 2,000. You currently have ${recipients} contacts selected. Please adjust your filter and try again with fewer contacts.`;
                }

                const options = Helper.swalWarningOptions('Alert!', message);

                Swal.fire(options);
            },
        },
    }
</script>

<style scoped>
    .forms-setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .reciever_wpr {
        display: flex;
        align-items: center;
    }

    .reciever_wpr .multiselect.small_box {
        border: 1px solid #d9d9d9;
        width: 150px;
        min-height: 40px;
        margin: 10px 7px;
        font-size: 14px;
        box-shadow: none;
    }

    .filter_tags {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        padding-top: 5px;
    }

    .filter_tags li {
        padding: 5px 15px;
        border-radius: 13px;
        background: #f2a31d;
        color: #fff;
        font-size: 13px;
        line-height: 18px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        width: auto;
        margin: 5px;
    }

    .color_container {
        position: relative;
    }
    .container{
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
    }
    
    .close_area {
        width: 20px;
        height: 20px;
        font-size: 12px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: absolute;
        right: 10px;
        cursor: pointer;
        top: 10px;
    }

    .primary_btn {
        font-size: 15px;
        line-height: 18px;
        height: 40px;
        width: 200px;
        margin: 10px 0;
    }

    .progress_item {
        border-radius: 10px;
        max-width: 400px;
        margin: 0 auto;
        border: 1px solid #e0e0e0;
    }

    .progress_item .item_img img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
    }

    .progress_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .progress_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .progress_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .progress_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .step_name {
        display: flex;
        align-items: center;
    }

    .step_name h3 {
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #121525;
        padding: 5px 0;
        transition: all 0.3s ease-in-out;
    }

    .step_wpr h3:not(.sub_header) {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #32373b;
        padding: 5px 0;
    }

    .track_list {
        padding: 15px;
    }

    .track_list li {
        padding: 0 20px 20px 40px;
        position: relative;
    }

    .track_list li:not(:last-child):after {
        position: absolute;
        content: '';
        left: 14px;
        top: 0;
        bottom: 0;
        border-left: 2px dashed #b0d1ff;
    }

    .step_number {
        position: absolute;
        left: 4px;
        top: 0;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2f7eed;
        border: 3px solid #b0d1ff;
        color: #fff;
        z-index: 1;
        font-size: 9px;
        transition: all 0.3s ease-in-out;
    }

    .track_list li .step_name.active h3 {
        font-size: 15px;
        line-height: 20px;
    }

    .track_list li .step_name.active .step_number {
        font-size: 13px;
        left: 0;
        width: 30px;
        height: 30px;
    }

    .time_listing {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -7px;
    }

    .time_listing li {
        padding: 5px 7px;
        width: 20%;
    }

    .time_listing li .checkbox {
        display: block;
    }

    .selection_box {
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        border: 1px solid #d9d9d9;
        color: #121525;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .time_listing li .checkbox input[type=radio]:checked~.selection_box {
        border: 1px solid #b0d1ff;
        background: rgba(47, 126, 237, 0.05);
        color: #2f7eed;
    }

    .selection_box:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }

    .day_type {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    .day_type li {
        padding: 10px 15px;
        flex: 1 0 50%;
    }

    .day_type li .day_wpr {
        display: flex;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
    }

    .day_type li .day_wpr label {
        width: 50px;
        height: 45px;
        background: #fff;
        border-right: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px 0 0 5px;
    }

    .day_type li .day_wpr label span {
        width: 20px;
        height: 20px;
        border: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 3px;
        background: rgba(255, 255, 255, 0.3);
    }

    .day_type li .day_wpr label span i {
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .day_type li .day_wpr label input[type=radio]:checked~span i {
        transform: scale(1);
    }

    .day_type li .day_wpr h4 {
        font-size: 14px;
        line-height: 18px;
        padding: 7px 15px;
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    .sqr_input {
        width: 35px;
        height: 25px;
        border: 1px solid #d9d9d9;
        margin: 0 5px;
        text-align: center;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-size: 14px;
        font-weight: 500;
    }

    .date_input {
        width: 140px;
        height: 25px;
        border: 1px solid #d9d9d9;
        margin: 0 5px;
        padding: 0 5px;
        text-align: left;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-size: 13px;
        font-weight: 500;
    }

    .sqr_input::-webkit-outer-spin-button,
    .sqr_input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .sqr_input[type=number] {
        -moz-appearance: textfield;
    }

    .time_selection {
        display: flex;
        margin: 0 -15px;
    }

    .time_selection .form_grp {
        padding: 10px;
        width: 50%;
        margin: 0;
    }

    .day_sorting {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -5px;
    }

    .day_sorting li {
        padding: 5px;
        width: 25%;
        min-width: 90px;
    }

    .day_sorting li label {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 15px;
    }

    .circumference_list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 15px -10px;
    }

    .circumference_list li {
        padding: 10px;
        width: 33.333%;
    }

    .circumference_list li label {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        padding: 15px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .circumference_list li label:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .custom_list li {
        border: 1px solid #eaeaea;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 10px 15px;
        margin-bottom: 15px;
    }

    .custom_list li h5 {
        display: flex;
        align-items: center;
    }

    .custom_list li h5 label {
        padding: 5px 10px;
        margin-left: auto;
    }

    .custom_list li h5 .delete_btn {
        font-size: 16px;
        color: #eb1414;
        margin: 5px 10px;
    }

    .custom_list li .section_wpr {
        border-top: 1px solid #eaeaea;
        margin: 10px -15px 0 -15px;
        padding: 10px 30px;
    }

    .modal.add_question .modal_header {
        padding: 0 45px;
    }

    .modal.add_question .modal_body {
        padding: 30px 45px 35px 45px;
        display: block;
    }

    .mcq_list li {
        border: 1px solid #d9d9d9;
        padding: 0 15px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .mcq_list li input {
        padding: 12px 15px 10px 0;
        font-size: 14px;
        line-height: 19px;
    }

    .mcq_list li button {
        border-left: 1px solid #d9d9d9;
        padding: 0 0 0 15px;
    }

    .col-2 {
        display: flex;
        margin: 0 -15px;
    }

    .col-2 .color_palette {
        flex: 1 0 50%;
        padding: 10px 15px;
    }

    .preview_content {
        width: 40%;
        position: relative;
        margin-right: -40%;
        transition: all 0.3s ease-in-out;
        background: #fff;
        z-index: 4;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_area .preview_area .close_btn {
        right: 15px;
        top: 15px;
        left: auto;
        position: absolute;
        background: #eaeaea;
        width: 25px;
        height: 25px;
        border-radius: 50%;
    }

    /* .global_setting .close_btn {
                left: auto;
            } */
    .tab_sec li {
        display: flex;
        align-items: center;
    }

    .tab_sec .tag {
        padding: 2px 5px;
        border-radius: 7px;
        font-size: 8px;
        line-height: 10px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 5px;
    }

    .broadcast_info li {
        display: flex;
        align-items: flex-start;
        padding: 20px 0;
        /* border-bottom: 1px solid #e9e9e9; */
    }

    .broadcast_info li h4 {
        font-size: 15px;
        line-height: 24px;
        color: #121525;
        font-weight: 500;
        margin: 8px 10px 8px 0;
    }

    .broadcast_info li p {
        font-size: 15px;
        line-height: 24px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 8px 0;
    }
    .edit_section :deep(.field_wpr input){
        font-size: 16px;
        color: #5a5a5a;
    }

    .broadcast_info li p a {
        color: #2f7eed;
        cursor: pointer;
    }

    .broadcast_info li span {
        flex: 0 0 40px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f9f9f9;
        border: 1px solid #eaeaea;
        margin-right: 15px;
    }

    .broadcast_info li span img {
        width: 18px;
        height: auto;
        filter: grayscale(1);
    }

    .broadcast_info label {
        font-size: 11px;
        line-height: 13px;
        color: #fff;
        font-weight: 400;
        background: #f2a31d;
        border-radius: 4px;
        padding: 4px 10px;
        text-transform: capitalize;
        margin: 8px 0;
    }

    .broadcast_info label.draft {
        color: #f2a31d;
        background: #fff;
        border: 1px solid #f2a31d;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 40px auto;
    }

    .cell:after,
    .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        scroll-behavior: smooth;
    }

    .cell .content_area::-webkit-scrollbar {
        display: none;
    }

    .cell .msg_preview {
        padding: 20px;
        margin: 15px;
        background: #fff;
        border-radius: 10px;
        border: 1px solid #e9e9e9;
        position: relative;
    }

    .cell .msg_preview:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview p {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 10px;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        display: inline-block;
    }

    .cell .msgFooter :deep(p) {
        font-size: 11px;
        line-height: 17px;
    }

    .cell .msgFooter :deep(p a) {
        text-decoration: none;
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 5px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    .sender_info .info p {
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .m_btn {
        display: none;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 5px;
        font-size: 14px;
    }

    .preview_content.action_details .close_btn {
        position: fixed;
        right: 5px;
        left: auto;
        top: 100vh !important;
        transition: all 0.3s ease-in-out;
    }

    :deep(.email_comp form .action_wpr) {
        padding-right: 0;
        padding-left: 0;
        border-top: 0;
    }

    .broadcast_status {
        display: flex;
        justify-content: center;
        margin: 20px 0 -20px 0;
    }

    .broadcast_status span {
        font-size: 11px;
        line-height: 13px;
        padding: 4px 10px;
        border: 1px solid #f2a31d;
        color: #f2a31d;
        border-radius: 4px;
        margin: 0 auto;
    }

    :deep(.lg_editor .editorWpr .redactor-styles) {
        min-height: 25vw !important;
        resize: vertical;
    }

    .info_bar {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        margin: 0 auto;
        position: relative;
    }

    .info_bar .tutorial_btn {
        position: absolute;
        left: 100%;
        top: 25px;
        padding: 4px 10px;
        background: #fff;
        border-radius: 11px;
        font-size: 11px;
        line-height: 14px;
        color: #2f7eed;
        font-weight: 500;
        margin-left: 5%;
        cursor: pointer;
    }
    .step_bar2 {
        position: relative;
        padding: 20px 30px 10px 30px;
    }

    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
        text-align: center;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
        text-align: left;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
        text-align: right;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }
    .footer_area{
        background: #fafafb;
        border-top: 1px solid #f3f3f3;
        margin: 0 -30px;
        padding: 20px 30px;
    }
    .cloud_tag{
        padding: 8px 20px;
        border-radius: 20px;
        background: #999;
        color: #fff;
        font-size: 13px;
        line-height: 18px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        width: auto;
    }
    .action_wpr .btn img{
        filter: grayscale(1);
        max-height: 14px;
        width: auto;
        margin: 7px 8px 0 0;
        display: inline-block;
        vertical-align: top;
    }
    @media(max-width: 1399px) {
        .content_area .cell {
            transform: translateX(-50%) scale(0.8);
            margin: -30px 50% !important;
        }
    }

    @media(max-width: 991px) {
        .preview_content.action_details {
            width: 350px !important;
        }

        .action_wpr .btn {
            padding: 0 20px;
            font-size: 13px;
        }
    }

    @media(max-width: 767px) {
        .setting_wpr .form_grp {
            flex-direction: column;
            align-items: stretch;
            gap: 20px;
        }

        .reciever_wpr {
            font-size: 13px;
            line-height: 17px;
            flex-wrap: wrap;
        }

        .reciever_wpr .multiselect.small_box {
            width: 95px;
            font-size: 13px;
            min-height: 30px;
        }

        .preview_content.action_details {
            left: 0;
            position: fixed;
            height: calc(100vh - 85px);
            top: 100vh;
            z-index: 6;
            width: 100% !important;
            min-width: auto;
            transition: all .3s ease-in-out;
            border-radius: 20px 20px 0 0;
            box-shadow: 0 -2px 30px rgb(0, 0, 0, 0.15);
        }

        .global_setting.show_m_action .preview_content.action_details {
            top: 85px;
        }

        .m_btn {
            display: block;
        }

        .global_setting.show_m_action .preview_content.action_details .close_btn {
            top: 90px !important;
        }

        .global_setting .tabs_content form>.action_wpr {
            padding: 20px 30px;
            gap: 10px;
        }

        .action_wpr {
            gap: 10px;
        }

        .action_wpr .btn {
            font-size: 11px;
            min-width: auto;
            margin: 0;
        }
    }

    @media(max-width: 499px) {
        .content_area .cell {
            transform: translateX(-50%) scale(0.6);
            margin: -30% 50% !important;
        }
    }
</style>
